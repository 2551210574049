export const byAppleMusic = [
  {
    coverImgUrl:
      "https://p2.music.126.net/GvYQoflE99eoeGi9jG4Bsw==/109951165375336156.jpg",
    name: "Happy Hits",
    id: 5278068783,
  },
  {
    coverImgUrl:
      "https://p2.music.126.net/5CJeYN35LnzRDsv5Lcs0-Q==/109951165374966765.jpg",
    name: "\u4e2d\u563b\u5408\u74a7",
    id: 5277771961,
  },

  {
    coverImgUrl:
      "https://p1.music.126.net/cPaBXr1wZSg86ddl47AK7Q==/109951165375130918.jpg",
    name: "Heartbreak Pop",
    id: 5277965913,
  },
  {
    coverImgUrl:
      "https://p2.music.126.net/FDtX55P2NjccDna-LBj9PA==/109951165375065973.jpg",
    name: "Festival Bangers",
    id: 5277969451,
  },
  {
    coverImgUrl:
      "https://p2.music.126.net/hC0q2dGbOWHVfg4nkhIXPg==/109951165374881177.jpg",
    name: "Bedtime Beats",
    id: 5277778542,
  },
];

export const playlistCategories = [
  {
    name: "全部",
    enable: true,
    bigCat: "static",
  },
  {
    name: "推荐歌单",
    enable: true,
    bigCat: "static",
  },
  // {
  //   name: "最新专辑",
  //   enable: false,
  //   bigCat: "static",
  // },
  {
    name: "精品歌单",
    enable: true,
    bigCat: "static",
  },
  {
    name: "官方",
    enable: true,
    bigCat: "static",
  },
  {
    name: "排行榜",
    enable: true,
    bigCat: "static",
  },
  {
    name: "华语",
    enable: false,
    bigCat: "语种",
  },
  {
    name: "欧美",
    enable: true,
    bigCat: "语种",
  },
  {
    name: "日语",
    enable: false,
    bigCat: "语种",
  },
  {
    name: "韩语",
    enable: false,
    bigCat: "语种",
  },
  {
    name: "粤语",
    enable: false,
    bigCat: "语种",
  },
  {
    name: "流行",
    enable: true,
    bigCat: "风格",
  },
  {
    name: "摇滚",
    enable: true,
    bigCat: "风格",
  },
  {
    name: "民谣",
    enable: false,
    bigCat: "风格",
  },
  {
    name: "电子",
    enable: true,
    bigCat: "风格",
  },
  {
    name: "舞曲",
    enable: false,
    bigCat: "风格",
  },
  {
    name: "说唱",
    enable: true,
    bigCat: "风格",
  },
  {
    name: "轻音乐",
    enable: false,
    bigCat: "风格",
  },
  {
    name: "爵士",
    enable: false,
    bigCat: "风格",
  },
  {
    name: "乡村",
    enable: false,
    bigCat: "风格",
  },
  {
    name: "R&B/Soul",
    enable: false,
    bigCat: "风格",
  },
  {
    name: "古典",
    enable: false,
    bigCat: "风格",
  },
  {
    name: "民族",
    enable: false,
    bigCat: "风格",
  },
  {
    name: "英伦",
    enable: false,
    bigCat: "风格",
  },
  {
    name: "金属",
    enable: false,
    bigCat: "风格",
  },
  {
    name: "朋克",
    enable: false,
    bigCat: "风格",
  },
  {
    name: "蓝调",
    enable: false,
    bigCat: "风格",
  },
  {
    name: "雷鬼",
    enable: false,
    bigCat: "风格",
  },
  {
    name: "世界音乐",
    enable: false,
    bigCat: "风格",
  },
  {
    name: "拉丁",
    enable: false,
    bigCat: "风格",
  },
  {
    name: "New Age",
    enable: false,
    bigCat: "风格",
  },
  {
    name: "古风",
    enable: false,
    bigCat: "风格",
  },
  {
    name: "后摇",
    enable: false,
    bigCat: "风格",
  },
  {
    name: "Bossa Nova",
    enable: false,
    bigCat: "风格",
  },
  {
    name: "清晨",
    enable: false,
    bigCat: "场景",
  },
  {
    name: "夜晚",
    enable: false,
    bigCat: "场景",
  },
  {
    name: "学习",
    enable: false,
    bigCat: "场景",
  },
  {
    name: "工作",
    enable: false,
    bigCat: "场景",
  },
  {
    name: "午休",
    enable: false,
    bigCat: "场景",
  },
  {
    name: "下午茶",
    enable: false,
    bigCat: "场景",
  },
  {
    name: "地铁",
    enable: false,
    bigCat: "场景",
  },
  {
    name: "驾车",
    enable: false,
    bigCat: "场景",
  },
  {
    name: "运动",
    enable: false,
    bigCat: "场景",
  },
  {
    name: "旅行",
    enable: false,
    bigCat: "场景",
  },
  {
    name: "散步",
    enable: false,
    bigCat: "场景",
  },
  {
    name: "酒吧",
    enable: false,
    bigCat: "场景",
  },
  {
    name: "怀旧",
    enable: false,
    bigCat: "情感",
  },
  {
    name: "清新",
    enable: false,
    bigCat: "情感",
  },
  {
    name: "浪漫",
    enable: false,
    bigCat: "情感",
  },
  {
    name: "伤感",
    enable: false,
    bigCat: "情感",
  },
  {
    name: "治愈",
    enable: false,
    bigCat: "情感",
  },
  {
    name: "放松",
    enable: false,
    bigCat: "情感",
  },
  {
    name: "孤独",
    enable: false,
    bigCat: "情感",
  },
  {
    name: "感动",
    enable: false,
    bigCat: "情感",
  },
  {
    name: "兴奋",
    enable: false,
    bigCat: "情感",
  },
  {
    name: "快乐",
    enable: false,
    bigCat: "情感",
  },
  {
    name: "安静",
    enable: false,
    bigCat: "情感",
  },
  {
    name: "思念",
    enable: false,
    bigCat: "情感",
  },
  {
    name: "综艺",
    enable: false,
    bigCat: "主题",
  },
  {
    name: "影视原声",
    enable: false,
    bigCat: "主题",
  },
  {
    name: "ACG",
    enable: true,
    bigCat: "主题",
  },
  {
    name: "儿童",
    enable: false,
    bigCat: "主题",
  },
  {
    name: "校园",
    enable: false,
    bigCat: "主题",
  },
  {
    name: "游戏",
    enable: false,
    bigCat: "主题",
  },
  {
    name: "70后",
    enable: false,
    bigCat: "主题",
  },
  {
    name: "80后",
    enable: false,
    bigCat: "主题",
  },
  {
    name: "90后",
    enable: false,
    bigCat: "主题",
  },
  {
    name: "网络歌曲",
    enable: false,
    bigCat: "主题",
  },
  {
    name: "KTV",
    enable: false,
    bigCat: "主题",
  },
  {
    name: "经典",
    enable: false,
    bigCat: "主题",
  },
  {
    name: "翻唱",
    enable: false,
    bigCat: "主题",
  },
  {
    name: "吉他",
    enable: false,
    bigCat: "主题",
  },
  {
    name: "钢琴",
    enable: false,
    bigCat: "主题",
  },
  {
    name: "器乐",
    enable: false,
    bigCat: "主题",
  },
  {
    name: "榜单",
    enable: false,
    bigCat: "主题",
  },
  {
    name: "00后",
    enable: false,
    bigCat: "主题",
  },
];
